<template>
    <div class="page">
        <h3>{{user.current.nickname}}</h3>

        <ul>
            <li><router-link to="/subscription">Subscription</router-link></li>
            <li><router-link to="/connected-apps">Connected Apps</router-link></li>
        </ul>

		<div class="action-bar-container">
			<div class="action-bar-row">
				<router-link class="btn btn-action" to="/board">
					<i class="fal fa-fw fa-arrow-left"></i>
				</router-link>
			</div>
		</div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            user: "user/data"
        })
    }
}
</script>